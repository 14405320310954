function headerAddOnScroll() {
  const header = document.getElementById('jsHeader'),
        headerWrap = document.getElementById('jsHeaderWrap');

  if (header) {
    if (!header.classList.contains('--scrolled')) {
      header.classList.add('--scrolled');
      header.classList.add('--scrolled-enter');
      headerWrap.classList.add('--width');

      setTimeout(() => {
        header.classList.remove('--scrolled-enter');
        header.classList.add('--scrolled-active');
      }, 651);
    }
  }
}

function headerRemoveOnScroll() {
  const header = document.getElementById('jsHeader'),
        headerWrap = document.getElementById('jsHeaderWrap');

  if (header) {
    if (header.classList.contains('--scrolled')) {
      header.classList.remove('--scrolled');
      header.classList.add('--scrolled-leave');
      headerWrap.classList.remove('--width');

      setTimeout(() => {
        header.classList.remove('--scrolled-leave');
        header.classList.remove('--scrolled-active');
      }, 501)
    }
  }
}

function headerAddClasses() {
  const header = document.getElementById('jsHeader'),
        headerWrap = document.getElementById('jsHeaderWrap');

  if (header) {
    header.classList.add('--scrolled');
    header.classList.add('--scrolled-active');
    headerWrap.classList.add('--width');
  }
}

function headerRemoveClasses() {
  const header = document.getElementById('jsHeader'),
        headerWrap = document.getElementById('jsHeaderWrap');

  if (header) {
    header.classList.remove('--scrolled');
    header.classList.remove('--scrolled-active');
    headerWrap.classList.remove('--width');
  }
}

export {
  headerAddOnScroll,
  headerRemoveOnScroll,
  headerAddClasses,
  headerRemoveClasses
};
