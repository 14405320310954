function openMenu() {
  const openBtn = document.getElementById('jsOpenMenu'),
        collapseMenu = document.getElementById('jsMenuCollapse');

  if (openBtn) {
    openBtn.addEventListener('click', () => {
      collapseMenu.classList.remove('d-none');
      setTimeout(() => {
        collapseMenu.classList.add('--collapse-enter');
      }, 1);
      setTimeout(() => {
        collapseMenu.classList.remove('--collapse-enter');
      }, 201);
      openBtn.setAttribute('aria-expanded', 'true');
    });
  }
}

function closeMenu() {
  const closeBtn = document.getElementById('jsCloseMenu'),
        openBtn = document.getElementById('jsOpenMenu'),
        collapseMenu = document.getElementById('jsMenuCollapse');

  if (closeBtn) {
    closeBtn.addEventListener('click', () => {
      collapseMenu.classList.add('--collapse-leave');
      setTimeout(() => {
        collapseMenu.classList.add('d-none');
        collapseMenu.classList.remove('--collapse-leave');
      }, 201);
      openBtn.setAttribute('aria-expanded', 'false');
    });
  }
}

function smallSizeMenu() {
  const menu = document.getElementById('jsNavigationMenu');

  if (menu) {
    menu.classList.add('--small');
  }
}

function defaultSizeMenu() {
  const menu = document.getElementById('jsNavigationMenu');

  if (menu) {
    menu.classList.remove('--small');
  }
}

function setBlurOverlay() {
  const lastElem = document.getElementsByClassName('lastelementoverlay'),
        closeMenu = document.getElementById('jsCloseMenu');

  if (lastElem.length > 0) {
    lastElem[0].addEventListener('blur', (event) => {
      closeMenu.focus();
    });
  }
}


openMenu();
closeMenu();
setBlurOverlay();

export {
  smallSizeMenu,
  defaultSizeMenu
};
