import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js';
import { isTouchEnabled } from '../helpers';

function casesSlider() {
  const slider = document.getElementById('jsCasesSlider'),
        mediaQuery = window.matchMedia('(max-width: 1199px)'),
        options = {
          container: '#jsCasesSlider',
          items: 1,
          center: false,
          loop: false,
          nav: false,
          speed: 400,
          responsive: {
            768: {
              items: 2
            }
          }
        };

  if (slider && mediaQuery.matches && !isTouchEnabled()) {
    const casesSlider = tns(options);
    return casesSlider;
  } else if (slider && isTouchEnabled()) {
    slider.classList.add('is-touch-enabled');
    const casesSlider = tns(options);
    return casesSlider;
  }
}

function relatedCasesSlider() {
  const slider = document.getElementById('jsRelatedCasesSlider'),
        options = {
          container: '#jsRelatedCasesSlider',
          items: 1,
          center: false,
          loop: false,
          nav: false,
          speed: 400,
          responsive: {
            768: {
              items: 2
            },
            1200: {
              items: 3
            }
          }
        };

  if (slider) {
    const casesSlider = tns(options);
    return casesSlider;
  }
}

relatedCasesSlider();

export {
  casesSlider
};
