import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js';

function toolsSlider() {
  const slider = document.getElementById('jsToolsSlider'),
        options = {
          container: '#jsToolsSlider',
          items: 1,
          center: false,
          loop: false,
          nav: false,
          speed: 400,
          responsive: {
            768: {
              items: 2
            },
            1200: {
              items: 3
            }
          }
        };

  if (slider) {
    const toolsSlider = tns(options);
    return toolsSlider;
  }
}
toolsSlider();
