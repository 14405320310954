import JustValidate from 'just-validate';

function initToolkitForm() {
  const formToolkit = document.getElementById('jsToolkitForm');

  if (formToolkit) {
    const validate = new JustValidate('#jsToolkitForm', {
      errorFieldStyle: {
        color: '#FF0000'
      },
      errorFieldCssClass: 'is-invalid',
      errorLabelStyle: {
        color: '#FF0000'
      }
    });

    validate.addField('#fullname', [
      {
        rule: 'required',
        errorMessage: 'Vul je naam in'
      },
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'maxLength',
        value: 40
      }
    ])
    validate.addField('#email', [
      {
        rule: 'required',
        errorMessage: 'Vul je e-mailadres in'
      },
      {
        rule: 'email',
        errorMessage: 'Vul een geldig e-mailadres in'
      }
    ])
    return validate;
  }
}

function initSubscribeNewsletterForm() {
  const formToolkit = document.getElementById('jsSubscribeNewsletterForm');

  if (formToolkit) {
    const validate = new JustValidate('#jsSubscribeNewsletterForm', {
      errorFieldStyle: {
        color: '#FF0000'
      },
      errorFieldCssClass: 'is-invalid',
      errorLabelStyle: {
        color: '#FF0000'
      }
    });

    validate.addField('#fullname', [
      {
        rule: 'required',
        errorMessage: 'Vul je naam in'
      },
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'maxLength',
        value: 40
      }
    ])
    validate.addField('#email', [
      {
        rule: 'required',
        errorMessage: 'Vul je e-mailadres in'
      },
      {
        rule: 'email',
        errorMessage: 'Vul een geldig e-mailadres in'
      }
    ])
    return validate;
  }
}

function AddEventDownloadFormSubmit() {
  // look for all elements with class and add onclick event
  const element = document.getElementById('js-download-form-submit');
  if (element) {
    element.addEventListener('click', function(event) {
      event.stopImmediatePropagation();
      const result = getForm();
      if (result) {
        submitForm(result);
      }
    }, false);
  }
}

function AddEventSubscribeNewsletterFormSubmit() {
  // look for all elements with class and add onclick event
  const element = document.getElementById('js-subscribenewsletter-form-submit');
  if (element) {
    element.addEventListener('click', function(event) {
      event.stopImmediatePropagation();
      const result = getFormSubscribeNewsletter();
      if (result) {
        submitFormSubscribeNewsletter(result);
      }
    }, false);
  }
}

function getForm() {
  const name = document.getElementById('fullname');
  const company = document.getElementById('company');
  const email = document.getElementById('email');
  const phonenumber = document.getElementById('phonenumber');

  let subscribe = false;
  if (document.getElementById('newsletter') != null) {
    console.log('check:' + document.getElementById('newsletter').value);
    if (document.getElementById('newsletter').checked) {
      subscribe = true;
    }
  }

  const item = document.getElementById('item').value;

  return  {
    "name" : name.value,
    "organisation" : company.value,
    "email" : email.value,
    "phonenumber" : phonenumber.value,
    "subscribenewsletter" : subscribe,
    "item" : item
  }
}

function getFormSubscribeNewsletter() {
  const email = document.getElementById('email');
  const name = document.getElementById('fullname');

  return  {
    "name" : name.value,
    "email" : email.value
  }
}

function submitForm(data) {
  const xhr = new XMLHttpRequest();
  const url = window.location.protocol + "//" + window.location.host + "/umbraco/api/subscribedownload/postdownloadsubscriber" ;
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 201) {
      document.getElementById('js-input-form').classList.add('d-none');
      document.getElementById('js-result-form').classList.remove('d-none');
    } 
  };
  xhr.send(JSON.stringify(data));
}

function submitFormSubscribeNewsletter(data) {
  const xhr = new XMLHttpRequest();
  const url = window.location.protocol + "//" + window.location.host + "/umbraco/api/subscribenewsletter/postsubscribenewsletter" ;
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 201) {
      document.getElementById('js-input-subscribenewsletterform').classList.add('d-none');
      document.getElementById('js-result-subscribenewsletterform').classList.remove('d-none');
    }
  };
  xhr.send(JSON.stringify(data));
}

AddEventDownloadFormSubmit();
AddEventSubscribeNewsletterFormSubmit();

initToolkitForm();
initSubscribeNewsletterForm();
