function videoSwitch() {
    // look for all elements with class and add onclick event
    const elements = document.getElementsByClassName('js-btn-video-block');
    for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', function() {
            switchToVideo(this);
        }, false);
    }
    // replace clicked object with iframe video
    function switchToVideo(e) {
        e.parentElement.innerHTML = '<iframe src="' + e.getAttribute('data-url') + '" title="' + e.getAttribute('data-title') + '" allowFullScreen="allowFullScreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>';
    }
}
videoSwitch();