import { isTouchEnabled } from '../helpers';

function setTouchEnabledClass() {
  const projects = document.getElementById('jsProjects');

  if (projects) {
    if (isTouchEnabled()) {
      projects.classList.add('is-touch-enabled');
    } else {
      projects.classList.remove('is-touch-enabled');
    }
  }
}

function AddEventFilterButton() {
  // look for all elements with class and add onclick event
  const elements = document.getElementsByClassName('js-filter');
  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', function() {
      window.location.href = this.getAttribute('data-url');
    }, false);
  }
}

setTouchEnabledClass();
AddEventFilterButton();