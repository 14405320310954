function showMoreBlogs() {
  const button = document.getElementById('jsShowMoreBlogs'),
        buttonWrap = document.getElementById('jsShowMoreAction'),
        listItems = document.querySelectorAll('.blogs__list-item');

  if (button) {
    button.addEventListener('click', () => {
      for (i = 0; i < listItems.length; i++) {
        if (listItems[i].classList.contains('d-none')) {
          listItems[i].classList.remove('d-none');
        }
        if (listItems[i].classList.contains('--noborderbottom')) {
          listItems[i].classList.remove('--noborderbottom');
        }
      }
      buttonWrap.classList.add('d-none');
    });
  }
}

function readingTime() {
  const article = document.getElementById("js-article");
  if (article)
  {
    const text = article.innerText;
    const wpm = 225;
    const words = text.trim().split(/\s+/).length;
    const time = Math.ceil(words / wpm);
    let readtime = '';
    if (time <= 1)
    {
      readtime = time + ' minuut';
    }
    else
    {
      readtime = time + ' minuten';
    }
    if (document.getElementById("js-readtime"))
    {
      document.getElementById("js-readtime").innerText = readtime;
    }
  }
}

readingTime();
showMoreBlogs();
