// import {
//   debounce
// } from './helpers';
import './vendors';
import {
  smallSizeMenu,
  defaultSizeMenu
} from './navigation';
import {
  headerAddOnScroll,
  headerRemoveOnScroll,
  headerAddClasses,
  headerRemoveClasses
} from './header';
import {
  casesSlider
} from './cases';
import './glider';
import './tools';
import './video';
import './blogs';
import './calltoaction';
import './projects';
import './umbracoforms';

// Scroll to top
if (history.scrollRestoration) {
  history.scrollRestoration = 'manual';
} else {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
}

// Init position and sets class on menu
const yCoordWindow = window.pageYOffset;
if (yCoordWindow > 10) {
  smallSizeMenu();
  headerAddClasses();
} else if (yCoordWindow < 5) {
  defaultSizeMenu();
  headerRemoveClasses();
}

//Init casesSlider
casesSlider();

// Actions on window scroll
window.addEventListener('scroll', () => {
  const yCoord = window.pageYOffset;

  if (yCoord > 10) {
    smallSizeMenu();
    headerAddOnScroll();
  } else if (yCoord < 5) {
    defaultSizeMenu();
    headerRemoveOnScroll();
  }
});

// Actions on window resize
// window.addEventListener('resize', debounce(() => {
//
// }, 50));
