import '../../../../node_modules/vanilla-cookieconsent/src/cookieconsent';

const cc = initCookieConsent(),
      gtagUpdate = "'consent', 'update'",
      grantedAll = {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      },
      deniedAll = {
        'ad_storage': 'denied',
        'analytics_storage': 'denied'
      },
      grantedAnalyticsOnly = {
        'ad_storage': 'denied',
        'analytics_storage': 'granted'
      },
      grantedAdOnly = {
        'ad_storage': 'granted',
        'analytics_storage': 'denied'
      },
      options = {
        current_lang: document.documentElement.getAttribute('lang'),
        cookie_expiration: 365,
        auto_language: 'document',
        autoclear_cookies: true,
        onFirstAction: (cookie) => {
          if (typeof gtag === "function") {
            if (cookie.accept_type === 'all') {
              gtag(gtagUpdate, grantedAll);
              console.log('grant all')
            } else {
              gtag(gtagUpdate, deniedAll);
              console.log('denny all')
            }
          } else {
            console.log('no analytics')
          }
        },
        onAccept: (cookie) => {
          if (typeof gtag === "function") {
            const consentState = (state) => {
              const consent = cookie.level.includes(state);
              return consent;
            };

            if (consentState('analytics') && !consentState('marketing')) {
              gtag(gtagUpdate, grantedAnalyticsOnly);
              // console.log('onAccept: grantedAnalyticsOnly');
            } else if (!consentState('analytics') && consentState('marketing')) {
              gtag(gtagUpdate, grantedAdOnly);
              // console.log('onAccept: grantedAdOnly');
            } else if (consentState('analytics') && consentState('marketing')) {
              gtag(gtagUpdate, grantedAll);
              // console.log('onAccept: grantedAll');
            } else {
              gtag(gtagUpdate, deniedAll);
              // console.log('onAccept: deniedAll');
            }
          } else {
            console.log('no analytics')
          }
        },
        onChange: (cookie) => {
          if (typeof gtag === "function") {
            const consentState = (state) => {
              const consent = cookie.level.includes(state);
              return consent;
            };

            if (consentState('analytics') && !consentState('marketing')) {
              gtag(gtagUpdate, grantedAnalyticsOnly);
              // console.log('onChange: grantedAnalyticsOnly');
            } else if (!consentState('analytics') && consentState('marketing')) {
              gtag(gtagUpdate, grantedAdOnly);
              // console.log('onChange: grantedAdOnly');
            } else if (consentState('analytics') && consentState('marketing')) {
              gtag(gtagUpdate, grantedAll);
              // console.log('onChange: grantedAll');
            } else {
              gtag(gtagUpdate, deniedAll);
              // console.log('onChange: deniedAll');
            }
          } else {
            console.log('no analytics')
          }
        },
        languages: {
          nl: {
            consent_modal: {
              title: 'Deze website gebruikt cookies',
              description: 'Wij gebruiken cookies om jouw gebruikservaring te verbeteren, het webverkeer te analyseren en gerichte advertenties te kunnen tonen via derde partijen. Voor meer info, <a href="//therevolution.nl/privacy/">lees hier onze privacyverklaring</a>',
              primary_btn: {
                text: 'Ja, graag',
                role: 'accept_all'
              },
              secondary_btn: {
                text: 'Nee, liever niet',
                role: 'accept_necessary'
              }
            },
            settings_modal: {
              title: 'Cookie instellingen',
              save_settings_btn: 'Opslaan instellingen',
              accept_all_btn: 'Accepteren',
              close_btn_label: 'Sluiten',
              blocks: [
                {
                  title: 'Over cookies',
                  description: 'Wij maken gebruik van functionele, analytische en tracking cookies. Een cookie is een tekstbestandje dat bij het eerste bezoek aan deze website wordt opgeslagen door de browser van jouw computer, tablet of smartphone.'
                },
                {
                  title: 'Functionele cookies (altijd aan)',
                  description: 'Om onze website goed te laten werken en jou de beste ervaring te bieden zijn functionele cookies nodig.',
                  toggle: {
                    value: 'necessary',
                    readonly: true
                  }
                },
                {
                  title: 'Analytische cookies',
                  description: 'Google Analytics gebruikt deze cookies om de websitebeheerder een beeld te geven over de bezoekersstromen en klikgedrag.',
                  toggle: {
                    value: 'analytics',
                    enabled: false,
                    readonly: false
                  }
                },
                {
                  title: 'Marketing cookies',
                  description: 'Dit zijn cookies die door Google Ads worden geplaatst. Google Ads cookie is voor het tonen van advertenties via derde partijen.',
                  toggle: {
                    value: 'marketing',
                    enabled: false,
                    readonly: false
                  }
                }
              ]
            }
          }
        },
        gui_options: {
          consent_modal: {
            transition: 'slide'
          },
          settings_modal: {
            transition: 'slide'
          }
        }
      };

cc.run(options);

function gTagExists() {
  if (typeof gtag === "function") {

  }
}
