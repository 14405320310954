import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js';

function gliderSlider() {
  const slider = document.getElementById('jsGliderSlider'),
        options = {
          container: '#jsGliderSlider',
          controls: false,
          items: 1,
          mode: 'gallery',
          navPosition: 'bottom',
          speed: 400,
          onInit: () => {
            const buttons = document.querySelectorAll('.tns-nav button'),
                  slides = document.querySelectorAll('.glider__slide:not(.tns-slide-cloned)'),
                  services = [];

            for (let i = 0; i < slides.length; i++) {
              let allServices = '--' + slides[i].getAttribute('data-service');
              services.push(allServices);
            }
            for (let i = 0; i < buttons.length; i++) {
              buttons[i].innerHTML = i + 1;
              if (services.length > 0) {
                buttons[i].classList.add(services[i]);
              }
            }
          }
        };
  if (slider) {
    const gliderSlider = tns(options);
    return gliderSlider;
  }
}
gliderSlider();
